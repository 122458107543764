.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.avatar {
  width: 80%;
  height: 80%;
  max-height: 300px;
  max-width: 300px;
  margin-bottom: 16px;
  border: solid black 5px;
  border-radius: 50%;
  background-position: top -12px left 0;
  background-image: url("me.png");
  background-size: 300px;
}

.information {
  margin-bottom: 10%;
}
